import { Paragraph, unit, makeForm, useDocumentUrl } from '@aubade/core/ui-kit'

import { IconOutilsCouleurs } from '@aubade/design/graphics'
import { Avatar, useGetUser } from '@aubade/domain/components'
import type { User } from '@aubade/types/api'
import { HStack, VStack } from '@chakra-ui/react'

const { useWatch, Input } = makeForm<
  User.Read,
  'id' | 'firstname' | 'lastname' | 'avatar'
>()

type FormHeaderProps = {
  profile: 'customer' | 'collaborater'
}

export function FormHeader(props: FormHeaderProps) {
  const { profile } = props
  const { id: me } = useGetUser()
  const [id, firstname, lastname, avatar] = useWatch([
    'id',
    'firstname',
    'lastname',
    'avatar',
  ])

  const fullName = [firstname, lastname].filter(Boolean).join(' ')

  const canChangePicture = id === me && profile === 'collaborater'

  const pictureUrl = useDocumentUrl(avatar?.[0], {
    size: { width: 600, height: 600 },
    ratio: 'crop',
  })

  const title =
    id === me ? 'users.titles.headeryourprofile' : 'users.titles.headerprofile'

  return (
    <HStack width={'inherit'} gap={unit('20')} height={unit('80')}>
      {canChangePicture ? (
        <Input.DropZone
          name="avatar"
          accept={{ 'image/*': [] }}
          variant="profile"
          multiple={false}
          objectId={me}
          alt={fullName}
        />
      ) : (
        <Avatar
          name={fullName}
          picture={pictureUrl}
          variant="xxl"
          icon={profile === 'customer' ? IconOutilsCouleurs : undefined}
        />
      )}

      <VStack
        alignItems="flex-start!important"
        justifyContent="flex-start!important"
        maxHeight="inherit"
        gap={0}
      >
        <Paragraph text={title} />

        <Paragraph text={fullName} ellipsis size="lg" fontWeight="bold" />
      </VStack>
    </HStack>
  )
}
