import { filterValues, entryIsTruthy } from '@nartex/stdlib'
import { isValid, parseISO } from 'date-fns'
import { flatten, unflatten } from 'flat'
import { useMemo, useEffect, useCallback } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { isDeepEqual, mapValues } from 'remeda'

import { useDeepCompareMemo } from 'use-deep-compare'

type SearchParamsState = Record<string, any>

type useSearchParamsStateProps<T extends SearchParamsState> = {
  scope: string
  defaultState?: DeepPartial<T>
}
export function useSearchParamsState<T extends SearchParamsState>(
  props: useSearchParamsStateProps<T>,
) {
  const { scope, defaultState } = props
  const [searchParams, setSearchParams] = useSearchParams()

  useEnsureUniqueScope(scope)

  const memoizedDefaultState = useDeepCompareMemo(
    () => defaultState,
    [defaultState],
  )

  const searchParamsState = useMemo(
    () => deserialize(scope, searchParams, memoizedDefaultState),
    [searchParams, memoizedDefaultState, scope],
  )

  const setSearchParamsState = useCallback(
    (
      newSearchParamsState: DeepPartial<T>,
      transformSearchParam: (
        newSearchParams: Record<string, string>,
      ) => Record<string, string | undefined> = (newSearchParams) =>
        newSearchParams,
    ) => {
      const previousSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(
          ([key]) => !key.startsWith(scope),
        ),
      )
      const newSearchParams = {
        ...previousSearchParams,
        ...Object.fromEntries(
          serialize<T>(scope, newSearchParamsState as any, memoizedDefaultState) // newFiltersState is hard to type
            .entries(),
        ),
      }
      const transformedSearchParams = transformSearchParam(newSearchParams)
      const cleanedSearchParams = Object.entries(
        transformedSearchParams,
      ).filter<[string, string]>(entryIsTruthy)
      setSearchParams(cleanedSearchParams, { replace: true })
    },
    [memoizedDefaultState, scope, searchParams, setSearchParams],
  )

  return useMemo(
    () => [searchParamsState, setSearchParamsState] as const,
    [searchParamsState, setSearchParamsState],
  )
}

const scopeSet = new Set<string>()
function useEnsureUniqueScopeImpl(scope: string) {
  useEffect(() => {
    if (scopeSet.has(scope)) {
      throw new Error(
        `The scope key : ${JSON.stringify(scope)} is already used`,
      )
    }
    scopeSet.add(scope)

    return () => {
      scopeSet.delete(scope)
    }
  }, [scope])
}

export const useEnsureUniqueScope =
  process.env.NODE_ENV === 'development' ? useEnsureUniqueScopeImpl : () => {}

function serialize<T extends SearchParamsState>(
  scope: string,
  state: DeepPartial<T>,
  defaultState?: DeepPartial<T>,
): URLSearchParams {
  const parsedState = filterValues(
    flatten<DeepPartial<T>, Record<string, any>>(
      mapValues(state, (value, key) => {
        const defaultValue = defaultState?.[key]
        if (defaultValue !== undefined && isDeepEqual(value, defaultValue)) {
          return undefined
        }

        return value
      }) as DeepPartial<T>,
    ),
    (value) => value !== undefined,
  )

  const urlParams = Object.entries(parsedState).map(([key, value]) => {
    if (value && typeof value === 'object') {
      return [`${scope}.${String(key)}`, JSON.stringify(value)]
    }
    return [`${scope}.${String(key)}`, String(value)]
  })
  return new URLSearchParams(urlParams)
}

function deserialize<T extends SearchParamsState>(
  scope: string,
  searchParams: URLSearchParams,
  defaultState?: DeepPartial<T>,
): DeepPartial<T> {
  const allParams = Array.from(searchParams.entries())
  const scopedParams = allParams.filter(([key]) => key.startsWith(`${scope}.`))
  const parsedEntries = scopedParams.map(([key, value]) => {
    const cleanedKey = key.replace(new RegExp(`^${scope}.`), '')

    try {
      value = JSON.parse(value)
      const date = parseISO(value)
      if (isValid(date)) {
        return [cleanedKey, date]
      }
      return [cleanedKey, value]
    } catch {
      return [cleanedKey, value]
    }
  })

  const flatDefaultState = defaultState
    ? flatten<DeepPartial<T>, Record<string, any>>(defaultState)
    : {}

  const params = unflatten<Record<string, string>, Record<string, any>>({
    ...flatDefaultState,
    ...Object.fromEntries(parsedEntries),
  })

  return params as DeepPartial<T>
}

export const searchStateSerializer = {
  serialize,
  deserialize,
}
