import type { AppMode, INativeBridge } from '@aubade/core/adapters'
import {
  fileUploadProxy,
  defaultValuesProxy,
  HistoryRouter,
  RefineOverride,
  StorageProvider,
  HTTPClientProvider,
  NativeBridgeProvider,
  wrapHistory,
  HistoryRouterAdapter,
  useList,
} from '@aubade/core/adapters'
import {
  Button,
  ConfirmModalProvider,
  DesignSystemConfig,
  Layout,
  Paragraph,
  Title,
  useOnNavigateAway,
  unit,
} from '@aubade/core/ui-kit'
import { theme } from '@aubade/design'
import {
  IconFeed,
  IconPublications,
  IconMessage,
  IconOutils,
  IconEmployees,
  IconArtisan,
  IconNotification,
  IconStats,
  IconShop,
} from '@aubade/design/graphics'
import { themeColors } from '@aubade/design/themeColors'
import { actionsProxy, overrideActionRequests } from '@aubade/domain/actions'
import { AgencyProvider } from '@aubade/domain/adapters'
import { isAdmin, isSociety, useGetUser } from '@aubade/domain/components'
import { Profile } from '@aubade/domain/resources/Users/Profile'
import { useI18nProvider, useTranslate } from '@aubade/translation'
import { EXCEPTIONS } from '@aubade/types'
import { OidcSecure, useOidc } from '@axa-fr/react-oidc'
import { VStack, HStack, ChakraProvider, Box } from '@chakra-ui/react'
import {
  cleanHydraId,
  DataProvider,
  MetaDataProvider,
} from '@nartex/api-platform'
import type { FallbackRender } from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import { useQueryClient, type QueryClient } from '@tanstack/react-query'
import type { AxiosInstance } from 'axios'
import { createBrowserHistory } from 'history'

import type { PropsWithChildren } from 'react'
import { useMemo, useCallback, Suspense, useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { useMemoOne } from 'use-memo-one'

import { QueryParamProvider } from 'use-query-params'

import type { ExtendedConversationList } from '../../../packages/types/src/index'

import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import type { IEnv } from './adapters/Env'
import { EnvProvider } from './adapters/Env'
import { useRequestPermission } from './adapters/firebase'
import { PushNotifications } from './adapters/PushNotifications'

import { ScopeProvider, Scopes } from './components'
import { Auth } from './components/Auth'

// import { NativeDevPage } from './NativeDevPage'
import { Aubade } from './Resources/Aubade'
import { List } from './Resources/Conversations/List'
import { FeedList } from './Resources/Feed'
import { Functions } from './Resources/Functions'
import { NotificationsList } from './Resources/Notifications'
import { ToolsList } from './Resources/Outils'
import { PublicationsList } from './Resources/Publications'
import { CreateStandAlone } from './Resources/Publications/createStandAlone'
import { Statistics } from './Resources/Statistics'
import { CollaboratersList, CustomersList } from './Resources/Users'

export function useUrls() {
  const factory = App.useUrls()
  return useMemo(factory, [factory])
}

type Props = {
  mode: AppMode
  env: IEnv
  adapters: {
    httpClient: AxiosInstance
    storage: Storage
    nativeBridge: INativeBridge
    queryClient: QueryClient
  }
}
export const App = Object.assign(
  function App(props: Props) {
    const { env, mode } = props
    return (
      <Suspense fallback={<Layout.Loading />}>
        <Setup {...props}>
          <ChakraProvider theme={theme}>
            <Suspense fallback={<Layout.Loading />}>
              <RoutesRender env={env} mode={mode} />
            </Suspense>
          </ChakraProvider>
        </Setup>
      </Suspense>
    )
  },
  {
    useUrls() {
      const aubade = Aubade.useUrls()

      return useCallback(
        (prefix = '') => {
          /* eslint-disable @getify/proper-arrows/where */
          return {
            aubade: () => aubade(),
            prefix,
            __nativeDevPage: '__nativeDevPage',
          }
        },
        [aubade],
      )
    },
  },
)

type RouteRenderProps = {
  env: IEnv
  mode: AppMode
}
function RoutesRender(props: RouteRenderProps) {
  const { env, mode } = props
  const urls = App.useUrls()()

  return (
    <>
      <Routes>
        {/* {mode === 'embedded' && process.env.NODE_ENV !== 'production' && (
          <Route path={urls.__nativeDevPage} element={<NativeDevPage />} />
        )}  */}

        <Route path="*" element={<AubadeRoutes />} />
      </Routes>
      {mode === 'embedded' && env.APP_ENV !== 'production' && (
        <HStack position={'fixed'} top={16} right={4}>
          <Button
            variant="link"
            to={urls.__nativeDevPage}
            label="Tests natif"
          />
        </HStack>
      )}
    </>
  )
}

function AubadeRoutes() {
  return (
    <ErrorBoundary
      fallback={(errorInfo) => {
        // @ts-expect-error
        const errorCode = errorInfo.error.response.status

        return <Layout.Error code={errorCode} report={errorInfo} />
      }}
    >
      <Auth>
        <AubadeRoutesRender />
      </Auth>
    </ErrorBoundary>
  )
}

function AubadeRoutesRender() {
  const urls = App.useUrls()()

  const { role, agency } = useGetUser()
  const actualAgency = sessionStorage.getItem('agency')
  const defaultValue = actualAgency !== null ? actualAgency : agency?.['@id']
  const [selectedAgency, setSelectedAgency] = useState(defaultValue)

  const xContext = selectedAgency
    ? `agency@${cleanHydraId(selectedAgency)}`
    : undefined
  const headers = xContext ? { 'x-context': xContext } : {}

  const canSeeStats = isAdmin(role) || isSociety(role)

  return (
    <DesignSystemConfig>
      {/* <NxDataProvidersProvider
            value={{ default: dummyProvider, typesense:  }}
        ></NxDataProvidersProvider> */}
      <AgencyProvider
        value={{ agency: selectedAgency, changeAgency: setSelectedAgency }}
      >
        <MetaDataProvider
          value={{
            headers,
          }}
        >
          <Routes>
            <Route
              path={`${urls.aubade().publicationsCreateStandAlone()}/*`}
              element={<CreateStandAlone />}
            />
            <Route
              element={
                <OidcSecure>
                  <MainLayout />
                </OidcSecure>
              }
            >
              <Route
                path={`${urls.aubade().publications()}/*`}
                element={
                  <>
                    <PublicationsList />
                  </>
                }
              />
              <Route
                path={`${urls.aubade().feed()}/*`}
                element={<FeedList />}
              />
              <Route
                path={`${urls.aubade().collaboraters()}/*`}
                element={<CollaboratersList />}
              />
              <Route
                path={`${urls.aubade().customers()}/*`}
                element={
                  <>
                    {isAdmin(role) ? (
                      <Navigate replace to={urls.aubade().feed()} />
                    ) : (
                      <CustomersList />
                    )}
                  </>
                }
              />
              <Route
                path={`${urls.aubade().notifications()}/*`}
                element={<NotificationsList />}
              />
              <Route
                path={`${urls.aubade().conversation()}/*`}
                element={
                  <>
                    {isAdmin(role) ? (
                      <Navigate replace to={urls.aubade().feed()} />
                    ) : (
                      <List />
                    )}
                  </>
                }
              />
              <Route
                path={`${urls.aubade().tools()}/*`}
                element={
                  <>
                    {!isAdmin(role) ? (
                      <Navigate replace to={urls.aubade().feed()} />
                    ) : (
                      <ToolsList />
                    )}
                  </>
                }
              />
              <Route
                path={`${urls.aubade().statistics()}/*`}
                element={
                  <>
                    {canSeeStats ? (
                      <Statistics />
                    ) : (
                      <Navigate replace to={urls.aubade().feed()} />
                    )}
                  </>
                }
              />
              <Route
                path={`${urls.aubade().functions()}/*`}
                element={
                  <>
                    {!isAdmin(role) ? (
                      <Navigate replace to={urls.aubade().feed()} />
                    ) : (
                      <Functions />
                    )}
                  </>
                }
              />
              <Route
                path="/"
                element={<Navigate replace to={urls.aubade().feed()} />}
              />
              <Route path="*" element={<Layout.Error code={404} />} />
            </Route>
          </Routes>
          <PushNotifications />
        </MetaDataProvider>
      </AgencyProvider>
    </DesignSystemConfig>
  )
}

function MainLayout() {
  const urls = useUrls()
  const { NavItem } = Layout.Sider
  const { isAuthenticated } = useOidc()

  const { role } = useGetUser()
  const requestPermission = useRequestPermission(isAuthenticated)
  const [conversations] = useList({
    resource: 'summary/conversations',
    dataProviderName: 'default',
  })

  const translate = useTranslate()
  const queryClient = useQueryClient()

  const counter = useMemo(() => {
    if (!conversations) return 0
    let count = 0
    const extendedConvList =
      conversations as unknown as ExtendedConversationList
    extendedConvList.users.forEach((conversation) => {
      count = count + conversation.nbUnreadMessage
    })

    return count
  }, [conversations])

  const caSeeStats = isAdmin(role) || isSociety(role)

  return (
    <Layout.Sider
      errorBoundaryFallback={(errorInfo) => {
        if (errorInfo.error.message === EXCEPTIONS.ERROR_ACCESS_RESTRICTED) {
          return <RestrictedPageException report={errorInfo} />
        }

        return <Layout.Error code={500} report={errorInfo} />
      }}
      sider={
        <>
          <NavItem
            to={urls.aubade().publications()}
            icon={IconPublications}
            label="menu.publications"
            end
          />
          <NavItem
            to={urls.aubade().notifications()}
            icon={IconNotification}
            label="menu.notifications"
            end
          />
          <NavItem
            to={urls.aubade().feed()}
            label="menu.feed"
            icon={IconFeed}
          />
          <NavItem
            to={urls.aubade().collaboraters()}
            label="menu.collaboraters"
            icon={IconEmployees}
          />
          {!isAdmin(role) && (
            <NavItem
              to={urls.aubade().customers()}
              label="menu.customers"
              icon={IconArtisan}
            />
          )}
          {!isAdmin(role) && (
            <NavItem
              to={urls.aubade().conversation()}
              icon={IconMessage}
              label="menu.messaging.zero"
              end
              onClick={async () => {
                await queryClient.invalidateQueries({
                  predicate: (query) => {
                    const { queryKey } = query
                    return queryKey.includes('firebase')
                  },
                })
                await requestPermission()
              }}
            >
              <CounterIcon counter={counter} />
              {translate('menu.messaging.zero')}
            </NavItem>
          )}
          {isAdmin(role) && (
            <NavItem
              to={urls.aubade().tools()}
              label="menu.tools"
              icon={IconOutils}
            />
          )}
          {caSeeStats && (
            <NavItem
              to={urls.aubade().statistics()}
              label="menu.statistics"
              icon={IconStats}
            />
          )}
          {isAdmin(role) && (
            <NavItem
              to={urls.aubade().functions()}
              label="menu.functions"
              icon={IconShop}
            />
          )}
        </>
      }
      profileDrawer={<Profile />}
    />
  )
}

function CounterIcon(props: { counter: number }) {
  const { counter } = props
  if (counter === 0) return <></>
  if (counter <= 9) {
    return (
      <Box
        position="absolute"
        display="flex"
        bottom={unit('-5')}
        left={unit('10')}
        w="16px"
        h="16px"
        bg={themeColors.error.dark}
        borderRadius="8px"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Paragraph
          size="2xs"
          text={String(counter)}
          color="white"
          fontWeight="bold"
        />
      </Box>
    )
  }
  return (
    <Box
      position="absolute"
      bottom={unit('-2')}
      left={unit('12')}
      w="10px"
      h="10px"
      bg={themeColors.error.dark}
      borderRadius="5px"
    />
  )
}

function Setup(props: PropsWithChildren<Props>) {
  const { env, adapters, children } = props
  const { httpClient, storage, nativeBridge, queryClient } = adapters

  const i18nProvider = useI18nProvider()
  const dataProvider = useMemoOne(() => {
    const apiDataProvider = DataProvider({
      httpClient: httpClient,
      apiUrl: env.API_URL,
    })
    const withFileUpload = fileUploadProxy(apiDataProvider, {
      apiClient: httpClient,
      baseURL: env.S3_URL,
    })
    const withDefaultValues = defaultValuesProxy(withFileUpload)
    const withAntiCorruptionLayer = antiCorruptionLayerProxy(withDefaultValues)
    const withActions = actionsProxy(
      /* @ts-expect-error */
      withAntiCorruptionLayer,
      overrideActionRequests,
    )
    return withActions
  }, [env.API_URL, httpClient])

  const history = wrapHistory(module, createBrowserHistory())

  return (
    <HistoryRouter history={history} basename={env.BASE_PATH}>
      <QueryParamProvider adapter={HistoryRouterAdapter}>
        <ErrorBoundary
          fallback={(errorInfo) => {
            const code = errorInfo.error.name.includes('404') ? 404 : 500

            return <Layout.Error code={code} report={errorInfo} />
          }}
        >
          <StorageProvider value={storage}>
            <EnvProvider value={env}>
              <HTTPClientProvider value={httpClient}>
                <NativeBridgeProvider value={nativeBridge}>
                  <ScopeProvider value={Scopes}>
                    <RefineOverride
                      dataProviders={{
                        // @ts-expect-error
                        default: dataProvider,
                      }}
                      i18nProvider={i18nProvider}
                      queryClient={queryClient}
                    >
                      <DesignSystemConfig
                        s3={{ url: env.S3_URL, resizeUrl: env.RESIZE_URL }}
                      >
                        <ConfirmModalProvider>{children}</ConfirmModalProvider>
                      </DesignSystemConfig>
                    </RefineOverride>
                  </ScopeProvider>
                </NativeBridgeProvider>
              </HTTPClientProvider>
            </EnvProvider>
          </StorageProvider>
        </ErrorBoundary>
      </QueryParamProvider>
    </HistoryRouter>
  )
}

type ErrorProps = {
  report: Parameters<FallbackRender>[0]
}

function RestrictedPageException(props: ErrorProps) {
  const { report } = props
  const navigate = useNavigate()
  useOnNavigateAway(() => {
    report.resetError()
  })
  return (
    <VStack alignContent="center" margin={16}>
      <Title.H1 text={'pages.error.forbiddenAccess'} />
      <Button
        variant="primary"
        onClick={() => navigate(-1)}
        label="pages.error.backHome"
      />
    </VStack>
  )
}
