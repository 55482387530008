import type { FormSubmitProps } from '@aubade/core/ui-kit'
import { unit, makeForm } from '@aubade/core/ui-kit'
import { VStack } from '@chakra-ui/react'

import { config } from 'src/config'

import { Actions } from '../actions'
import { TargetSection, LinkSection } from '../Components'
import type { FormType } from '../types'

import { CategorySection } from './CategorySection'
import { NotificationSection } from './NotificationSection'
import { PictureSection } from './PictureSection'
import { PlanifySection } from './PlanifySection'

const { Input, useWatch, useFormContext } = makeForm<
  FormType,
  | 'notificationTitle'
  | 'notificationContent'
  | 'withNotification'
  | 'planify'
  | 'isInternal'
  | 'allCategories'
  | 'jobs'
  | 'isProAubade'
  | 'hasEaCard'
>()

type FormContentProps = FormSubmitProps & {
  newPulicationId?: string
  publicationId?: string
  canEdit?: boolean
  standAlone?: boolean
  canPartialEdit?: boolean
}

export function FormContent(props: FormContentProps) {
  const {
    newPulicationId,
    publicationId,
    standAlone = false,
    canPartialEdit = true,
    ...saveButtonProps
  } = props

  const { setValue } = useFormContext()
  const values = useWatch()
  const { features } = config

  return (
    <>
      <PictureSection
        canPartialEdit={canPartialEdit}
        publicationId={publicationId}
        newPulicationId={newPulicationId}
      />
      <Input.Text
        name="title"
        label="publications.fields.title"
        maxLength={200}
        readOnly={!canPartialEdit}
      />

      <Input.Markdown
        label="publications.fields.description"
        maxLength={500}
        name="content"
        readOnly={!canPartialEdit}
      />

      <Input.Checkbox
        name="isInternal"
        label="publications.fields.isInternal"
        inputLabel="publications.label.isInternal"
        onChange={() => {
          setValue('notificationTitle', undefined)
          setValue('notificationContent', undefined)
          setValue('withNotification', false)
          setValue('isProAubade', false)
          if (features.eACard) {
            setValue('hasEaCard', false)
          }

          setValue('allCategories', true)
          setValue('jobs', undefined)
        }}
      />

      <VStack gap={unit('40')} alignItems={'flex-start'} width="full">
        <TargetSection />
        <CategorySection />
        <LinkSection />
        <PlanifySection />
        <NotificationSection />
        <Actions
          values={values}
          standAlone={standAlone}
          canPartialEdit={canPartialEdit}
          newPublicationId={newPulicationId}
          saveButtonProps={saveButtonProps}
        />
      </VStack>
    </>
  )
}
