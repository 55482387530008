import {
  DropdownMenu,
  makeForm,
  Boundary,
  useReadOnly,
} from '@aubade/core/ui-kit'
import { Box } from '@chakra-ui/react'
import { useList } from '@nartex/data-provider/react'
import type { BaseSyntheticEvent } from 'react'

import type { FieldValues } from 'react-hook-form'

import { CheckboxInput } from '../../../../../packages/core/src/ui-kit/Inputs/Checkbox/index'
import type { JobEventJob } from '../../../../../packages/types/src/api/index'

const { useWatch, useFormContext } = makeForm()

type Props<T extends FieldValues> = {
  name: keyof T
  title: string
  readOnly?: boolean
  onChange?: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
}
export function CategoryFilter<T extends FieldValues>(props: Props<T>) {
  const { title, ...pickerProps } = props

  return (
    <DropdownMenu title={title}>
      <CategoryPicker<T> {...pickerProps} />
    </DropdownMenu>
  )
}

export function CategoryPicker<T extends FieldValues>(
  props: Omit<Props<T>, 'title'>,
) {
  const { name, onChange } = props

  const { setValue } = useFormContext()

  const watchedValue: string[] = useWatch<any>(name as any)

  const [categories] = useList<JobEventJob.Read>({
    resource: 'jobs/list',
    hasPagination: false,
  })

  const globalReadOnly = useReadOnly()

  const readOnly = props?.readOnly ?? globalReadOnly

  if (!categories) return <></>

  const valueSet = new Set<string>(watchedValue ?? [])

  return (
    <Box width="full" gap={5}>
      <Boundary>
        {categories.map((cat) => {
          const isChecked = watchedValue && watchedValue?.includes(cat.id)
          return (
            <CheckboxInput
              key={cat.id}
              label={cat.label}
              value={isChecked}
              disabled={readOnly}
              onChange={async (value) => {
                if (value) {
                  // @ts-ignore
                  valueSet.add(cat.id)
                } else {
                  valueSet.delete(cat.id)
                }
                const newCategory =
                  Array.from(valueSet).length > 0
                    ? Array.from(valueSet)
                    : undefined
                // @ts-ignore
                setValue(name, newCategory)
                await onChange?.()
              }}
            />
          )
        })}
      </Boundary>
    </Box>
  )
}
