import { Caption, makeForm, unit } from '@aubade/core/ui-kit'
import type { ExtendedUser } from '@aubade/types'
import { CustomerRole, UserRole } from '@aubade/types'
import type { UserFunction } from '@aubade/types/api'
import { HStack, VStack, Box } from '@chakra-ui/react'
import { useList } from '@nartex/data-provider/react'

import { useMemo } from 'react'

import { CategoryPicker } from '../../../../../../apps/bo/src/components/Filters/CategoryFilter'

import { isAdmin, isAgency, isSociety, useGetUser } from '../../../components'

const { Input, useWatch } = makeForm<
  ExtendedUser,
  'firstname' | 'lastname' | 'craftCompany' | 'agency' | 'acRoles' | 'id'
>()

type FormContentProps = {
  profile: 'customer' | 'collaborater'
}

export function FormContent(props: FormContentProps) {
  const { profile } = props
  const [acRoles] = useWatch(['acRoles', 'id'])

  const { role } = useGetUser()
  const isAubade = Boolean(acRoles && isAdmin(acRoles.AC as any))

  const canEdit = canEditJob(acRoles?.AC, role)

  const [userFunctions] = useList<UserFunction.Read>({
    resource: 'user_functions',
  })

  const userFunctionsOptions = useMemo(() => {
    const funcOpt = {}
    userFunctions?.forEach((userfunction) => {
      Object.assign(funcOpt, { [userfunction.id]: userfunction.name })
    })
    return funcOpt
  }, [userFunctions])

  return (
    <VStack width="full" gap={unit('40')}>
      {profile === 'customer' && (
        <Input.Text label="users.fields.username" name="username" disabled />
      )}
      <HStack width="full" gap={unit('20')}>
        <Input.Text label="users.fields.firstname" name="firstname" disabled />
        <Input.Text label="users.fields.lastname" name="lastname" disabled />
      </HStack>
      <Input.Text label="users.fields.email" name="email" disabled />
      {profile === 'customer' && (
        <Input.Text
          name="craftCompany.name"
          label="users.fields.craftCompany"
          disabled
        />
      )}

      {profile === 'customer' && (
        <Box width="full">
          <Caption as={'span'} text={'users.fields.craftJob'} />
          <CategoryPicker name="jobIds" readOnly />
          <Box height="16px" />
        </Box>
      )}
      <Input.Select
        label="users.fields.role"
        name="acRoles.AC"
        disabled
        optionsSelect={profile === 'customer' ? CustomerRole : UserRole}
      />
      {profile === 'collaborater' && !isAubade && (
        <Input.Select
          label="users.fields.job"
          name="userFunctionId"
          optionsSelect={userFunctionsOptions}
          disabled={!canEdit}
        />
      )}

      <HStack width="full" gap={unit('20')}>
        {!isAubade && (
          <>
            <Input.Text
              name="society.name"
              label="users.fields.society"
              disabled
            />
            <Input.Text
              name="agency.name"
              label="users.fields.agency"
              disabled
            />
          </>
        )}
      </HStack>
    </VStack>
  )
}

function canEditJob(
  userRole?: UserRole | CustomerRole,
  role?:
    | 'ROLE_SUPER_ADMIN'
    | 'ROLE_APP_CRAFTER_COMPANY'
    | 'ROLE_APP_CRAFTER_STORE'
    | 'ROLE_APP_CRAFTER_ADMIN',
) {
  if (!role || !userRole) return false
  if (isAdmin(role)) {
    return true
  }
  if (isSociety(role) && isAgency(userRole as any)) {
    return true
  }
  return false
}
