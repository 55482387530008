import { useMakeTest } from 'src/adapters/useVestTest'
import { create, enforce, group } from 'vest'

import { PublicationLinkEnum } from '../types'
import type { FormType } from '../types'

export function useFormValidation(isAgency: boolean) {
  const test = useMakeTest<FormType>()

  return create(function (data: FormType) {
    const {
      title,
      content,
      isNational,
      isAgencies,
      agencies,
      societies,
      documents,
      linkType,
      url,
      urlLabel,
      toolId,
      withNotification,
      notificationTitle,
      notificationContent,
    } = data

    group('draft', function () {
      test('title', 'publications.title.required', function () {
        enforce(title).isNotBlank()
      })
      test(
        'notificationTitle',
        'publications.notificationTitle.required',
        function () {
          if (withNotification) {
            enforce(notificationTitle).isNotBlank()
          }
        },
      )
      test(
        'notificationContent',
        'publications.notificationContent.required',
        function () {
          if (withNotification) {
            enforce(notificationContent).isNotBlank()
          }
        },
      )
    })

    group('publish', function () {
      test('title', 'publications.title.required', function () {
        enforce(title).isNotBlank()
      })
      test('content', 'publications.content.required', function () {
        enforce(content).isNotBlank()
      })
      test('agencies', 'publications.targets.required', function () {
        if (!isNational && !isAgencies && !isAgency) {
          const targets = [...agencies, ...societies]
          enforce(targets).isNotEmpty()
        }
      })

      test('documents', 'publications.documents.required', function () {
        enforce(documents).isNotUndefined().lengthNotEquals(0)
      })

      test('url', 'publications.url.required', function () {
        if (
          linkType ===
          PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK']
        ) {
          enforce(url).isNotBlank()
        }
      })
      test('urlLabel', 'publications.urlLabel.required', function () {
        if (
          linkType ===
          PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK']
        ) {
          enforce(urlLabel).isNotBlank()
        }
      })
      test('toolId', 'publications.toolId.required', function () {
        if (
          linkType === PublicationLinkEnum['enum./publicationlink/TOOL_LINK']
        ) {
          enforce(toolId).isNotBlank()
        }
      })
      test(
        'notificationTitle',
        'publications.notificationTitle.required',
        function () {
          if (withNotification) {
            enforce(notificationTitle).isNotBlank()
          }
        },
      )
      test(
        'notificationContent',
        'publications.notificationContent.required',
        function () {
          if (withNotification) {
            enforce(notificationContent).isNotBlank()
          }
        },
      )
    })
  })
}
