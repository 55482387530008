import type { DataProvider, CrudSort } from '@aubade/core/adapters'
import {
  type SearchResponse,
  encodePagination,
  encodeFilters,
} from '@nartex/typesense'
import { groupBy } from 'remeda'
import type { ExtendedTool } from 'src/Resources/Outils/types'

export function ToolsProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    async getOne(params) {
      const data = await dataProvider.getOne(params)
      const { document } = data.data as unknown as ExtendedTool

      let fullDocument
      if (document) {
        const { data: fullDocumentData } = await dataProvider.getOne({
          ...params,
          resource: 'documents',
          id: document.id!,
        })
        fullDocument = fullDocumentData
      }

      return {
        ...data,
        data: {
          ...data.data,
          document: { ...document, ...fullDocument },
          template: document ? 'logo' : 'text',
        } as any,
      }
    },
    async create(params) {
      const variables = params.variables as unknown as ExtendedTool
      const { template, url, lineOne, lineTwo } = variables
      let name: string = ''
      if (template === 'logo') {
        name = url
      } else {
        name = `${lineOne} ${lineTwo}`
      }

      return dataProvider.create({
        ...params,
        // @ts-ignore
        resource: 'tools/create',
        variables: {
          ...params.variables,
          name,
        },
      })
    },
    async update(params) {
      const { id } = params
      const variables = params.variables as unknown as ExtendedTool
      const { template, url, lineOne, lineTwo } = variables
      let name: string = ''
      if (template === 'logo') {
        name = url
      } else {
        name = `${lineOne} ${lineTwo}`
      }

      return dataProvider.custom!({
        ...params,
        method: 'put',
        url: `tools/${id}/update`,
        payload: {
          ...params.variables,

          name,
        },
      }) as any
    },
    async getList(params) {
      const { filters, hasPagination, pagination, sort, metaData } = params
      const { search, tsFilters } = groupBy(filters ?? [], (filter) => {
        if ('q' in filter) return 'search'
        if ('field' in filter) {
          return 'tsFilters'
        }
      })
      const sortBy = encodeSortBy(sort).sort_by
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `tools/ts/search`,
        metaData,
        payload: {
          searches: [
            {
              collection: 'tools',
              query_by: 'name',
              ...encodePagination(hasPagination ? pagination : {}),
              // @ts-expect-error
              ...encodeFilters([...(search ?? []), ...(tsFilters ?? [])]),
              sort_by: sortBy,
            },
          ],
        },
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
  }
}

function encodeSortBy<T>(sort: CrudSort<T>[] | undefined) {
  return {
    sort_by:
      sort === undefined
        ? undefined
        : sort
            .map((item) => {
              const { field, order } = item
              return `${field}:${order}`
            })
            .join(','),
  }
}
