import type { SearchFilter } from '@aubade/core/adapters'
import { useQueryParamState } from '@aubade/core/libs'
import { SearchBar, Boundary } from '@aubade/core/ui-kit'
import type { ExtendedTypesensesocieties } from '@aubade/types'
import { Box, Spinner, Center } from '@chakra-ui/react'
import { cleanHydraId } from '@nartex/data-provider'
import type { BaseSyntheticEvent } from 'react'
import { useMemo, useState, Fragment, useEffect } from 'react'
import { groupBy, sortBy } from 'remeda'
import { useInfiniteList } from 'src/libs/useInfiniteList'
import { StringParam } from 'use-query-params'

import { AccordionInput } from '../AccordionInput'

import { NationalFilter } from './nationalFilter'

type SocietyAgencyFilterProps = {
  single?: boolean
  onFilterChange?: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
  context?: 'collaborators'
  withNational?: boolean
}

/**
 * Component for filters and form, for agencies and societies, name agencies / societies
 * @param {string} societyId - the id of a single society
 * @param {boolean} single - if the filter is for single agency and single society
 * @param {function} onFilterChange - callback onFilterStateChange from useFilter, for filters only
 */
export function SocietyAgencyFilter(props: SocietyAgencyFilterProps) {
  const {
    onFilterChange,
    single = false,
    context,
    withNational = false,
  } = props

  const [selectedParent, setSelectedParent] = useState<string | undefined>(
    undefined,
  )

  const paramName = 'societies.q'
  const [queryValue] = useQueryParamState(paramName, StringParam)
  const listFilters = useMemo(() => {
    const search = queryValue
    if (search) {
      const qFilter: SearchFilter = { q: search }

      return [qFilter]
    }
  }, [queryValue])

  const {
    data: dataSocieties,
    fetchNextPage: fetchNextPageSociety,
    isFetchingNextPage: isFetchingNextPageSociety,
    hasNextPage: hasNextPageSociety,
  } = useInfiniteList<ExtendedTypesensesocieties, any>(
    'societies',
    listFilters,
    200,
  )

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteList<ExtendedTypesensesocieties, any>(
      'agencies',
      listFilters,
      200,
    )

  const societies = useMemo(() => {
    return (dataSocieties?.pages ?? []).flatMap((dataPage) => {
      return dataPage.data.map((item) => item)
    })
  }, [dataSocieties?.pages])

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      // eslint-disable-next-line
      fetchNextPage()
    }
    if (hasNextPageSociety && !isFetchingNextPageSociety) {
      // eslint-disable-next-line
      fetchNextPageSociety()
    }
  }, [
    fetchNextPage,
    fetchNextPageSociety,
    hasNextPage,
    hasNextPageSociety,
    isFetchingNextPage,
    isFetchingNextPageSociety,
  ])

  const groupedAgencies = useMemo(
    () =>
      groupBy(
        (data?.pages ?? []).flatMap((dataPage) => {
          return dataPage.data.map((item) => item)
        }),
        (soc) => soc['society.id'],
      ),
    [data],
  )

  return (
    <Fragment>
      <SearchBar scope="societies" />
      <Box maxHeight={'350px'} overflow={'auto'} padding={2} width="full">
        {withNational && (
          <NationalFilter onChange={onFilterChange} context={context} />
        )}
        <Boundary>
          {societies?.map((singleSociety) => {
            return (
              <AccordionInput
                key={singleSociety.id}
                id={singleSociety.id}
                societyAgencies={sortAgencies(
                  groupedAgencies[singleSociety.id],
                )}
                label={singleSociety.name!}
                onChange={onFilterChange}
                disabled={Boolean(
                  (selectedParent &&
                    cleanHydraId(selectedParent) !==
                      cleanHydraId(singleSociety.id)) ||
                    (single &&
                      selectedParent &&
                      cleanHydraId(selectedParent) !==
                        cleanHydraId(singleSociety.id)),
                )}
                single={single}
                setSelectedParent={setSelectedParent}
              />
            )
          })}
          {isFetchingNextPageSociety && (
            <Center padding={4}>
              <Spinner />
            </Center>
          )}
        </Boundary>
      </Box>
    </Fragment>
  )
}

function sortAgencies(societies: ExtendedTypesensesocieties[]) {
  if (!societies) return
  return sortBy(societies, (society) => society?.name ?? society?.sort_value)
}
