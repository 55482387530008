import { useEvent } from '@aubade/core/libs'
import { Paragraph, Button } from '@aubade/core/ui-kit'
import { VStack, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import type { MessagePayload } from 'firebase/messaging'
import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useUrls } from 'src/App'

import { useFirebase } from './firebase'

export function PushNotifications() {
  const location = useLocation()

  if (location && location.pathname.includes('publicationMobile')) {
    return <></>
  }

  return <PushNotificationImpl />
}

function PushNotificationImpl() {
  const toast = useToast()
  const queryClient = useQueryClient()
  const urls = useUrls()
  useFirebaseMesageHandler(async (payload) => {
    await queryClient.invalidateQueries({
      predicate: (query) => {
        const { queryKey } = query
        console.log('queryKey', queryKey)

        return (
          queryKey.includes('summary/conversations') ||
          queryKey.includes('getList')
        )
      },
    })
    toast({
      title: (
        <Paragraph
          fontWeight="bold"
          size="md"
          color={'lightGrey.500'}
          text={payload!.notification!.title!}
        />
      ),
      description: (
        <VStack width="full" gap={2} alignItems={'flex-start'}>
          <Paragraph
            size="md"
            color={'lightGrey.500'}
            text={payload!.notification!.body!}
          />
          <Button
            variant="link"
            label={'actions.showConversation'}
            to={urls.aubade().conversationDetail(payload!.data!.conversationId)}
          />
        </VStack>
      ),

      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top',
    })
  })

  return <></>
}

function useFirebaseMesageHandler(
  messageHandler: (payload: MessagePayload) => void,
) {
  const firebase = useFirebase()

  const messageListener = useEvent(messageHandler)

  useEffect(() => {
    const unsubscribe = firebase?.onMessage?.(messageListener)
    return unsubscribe
  }, [firebase, messageListener])
}
