import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Surface,
  unit,
  Button,
  ResourceList,
  Paragraph,
} from '@aubade/core/ui-kit'
import { IconMessaging, IconRecherche } from '@aubade/design/graphics'
import { ProfileCard, isAdmin, useGetUser } from '@aubade/domain/components'
import type { Typesense } from '@aubade/types/typesense'
import { VStack, HStack } from '@chakra-ui/react'
import { useCreate } from '@nartex/data-provider/react'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { startsWith } from 'remeda'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { useUrls } from '../../../App'

import { ListHeader } from '../../../components/ListHeader'
import { EditUser } from '../CreateEdit'
import { useUserFilters } from '../filters'
import { FilterBar } from '../filters/FilterForm'

const scope = 'collaboraters'
const templateColumns = '1fr 1fr 200px'

export function CollaboratersList() {
  return (
    <ResourcePageLayout>
      <List />
      <EditUser profile="collaborater" />
    </ResourcePageLayout>
  )
}

function List() {
  const columns = useGetColumns()
  const { filtersStore, crudFilters } = useUserFilters(scope)
  const urls = useUrls()
  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
      paddingX={unit('10')}
      paddingY={unit('40')}
    >
      <ListHeader
        scope={scope}
        filters={
          <FilterBar
            onChange={filtersStore.setState}
            filterState={filtersStore.state}
            scope={scope}
          />
        }
      />
      <ResourceList<Typesense.Associates>
        scope={scope}
        resource="associates"
        dataProviderName="default"
        filters={crudFilters}
        tableProps={{
          templateColumns: templateColumns,
          columns: columns,
          hasPagination: true,
          noDataLabel: 'table.noData.collaboraters',
          getRowProps: useCallback<GetRowProps<Typesense.Clients>>(
            (row) => {
              return {
                to: urls.aubade().userDetail('collaborateurs', row.id),
              }
            },
            [urls],
          ),
        }}
      />
    </Surface>
  )
}

function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'avatar',
        cell(props) {
          const { row } = props
          const {
            firstname,
            lastname,
            avatar,
            'userFunction.name': userJob,
            roles,
          } = row.original

          const role = roles?.find(startsWith('ROLE_APP'))

          const fullName = [firstname, lastname].filter(Boolean).join(' ')

          return (
            <VStack width="full" height="full" alignItems="flex-start">
              <ProfileCard
                name={fullName}
                picture={avatar}
                role={userJob ?? role ?? ''}
                variant="big"
              />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'roles',
        cell(props) {
          const { row } = props
          const { 'agency.name': agency, 'society.name': society } =
            row.original

          const societyAgency = [society, agency].filter(Boolean).join(' - ')
          return (
            <VStack
              width="full"
              height="full"
              justifyContent={'center'}
              alignItems={'flex-start'}
            >
              <Paragraph size="sm" text={societyAgency} />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps
          const { id } = row.original
          return <Actions userId={id} />
        },
      },
    ] satisfies ColumnDef<Typesense.Associates>[]
  }, [])
}

type ActionsProps = {
  userId: string
}

function Actions(props: ActionsProps) {
  const { userId } = props
  const { role, id: me } = useGetUser()
  const urls = useUrls()

  const [create] = useCreate()
  const navigate = useNavigate()
  async function createConversation() {
    await create(
      {
        resource: 'conversations',
        values: {
          users: [userId],
        },
      },
      {
        async onSuccess(response) {
          navigate(
            urls.aubade().conversationDetail(response.data.id! as string),
          )
        },
      },
    )
  }

  return (
    <HStack
      gap="10px"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      {!isAdmin(role) && userId !== me && (
        <Button
          variant="circleList"
          leftIcon={IconMessaging}
          onClick={() => createConversation()}
        />
      )}

      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().userDetail('collaborateurs', userId)}
      />
    </HStack>
  )
}
