import type { ExtendedConversation } from '@aubade/types'
import type { CrudFilters, DataProvider } from '@nartex/data-provider'

import { recordFilters } from './collaboraters'

export function SummaryProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-expect-error
    async getList(params) {
      const { filters } = params
      const data = await dataProvider.custom!<ExtendedConversation[]>({
        ...(params as any),
        ...params?.metaData,
        method: 'post',
        payload: encodeSummaryFilters(filters),
        url: `summary/conversations`,
      })

      const decodedData = decodeSummary(data.data)

      return {
        ...data,
        data: decodedData,
        total: data.data.length,
      }
    },
  }
}

function encodeSummaryFilters<T>(filters: CrudFilters<T> | undefined) {
  const encodedFilters = recordFilters(filters)
  if (!encodedFilters) return undefined
  return {
    users: encodedFilters,
    messages: encodedFilters,
  }
}

function decodeSummary(
  data:
    | ExtendedConversation[]
    | { users: ExtendedConversation[]; messages: ExtendedConversation[] },
) {
  if (Array.isArray(data)) return { users: data, messages: data }
  return data
}
