import type { Merge } from '@aubade/core/libs'

import type {
  Publication,
  PostMessageEventPostMessageRequest,
  Conversation,
  GetSummaryConversationSummaryConversation,
  CraftCompany,
  BlackList,
  Notification,
  UserFunction,
} from '@aubade/types/api'

import type { Agency, Society, User } from './api'
import type { Message, Api } from './api/index'
import type { Typesense } from './typesense'

export * from './utilityTypes'

export enum EXCEPTIONS {
  LOGGED_USER_IS_NOT_ADMIN = 'handledException.loggedUser.notAdmin',
  LOGGED_USER_IS_ADMIN = 'handledException.loggedUser.admin',
  ERROR_ACCESS_RESTRICTED = 'handledException.loggedUser.accessRestricted',
  LOGGED_USER_HAS_NO_AGENCY = 'handledException.loggedUser.noAgency',
  AGENCY_UNAUTHORIZED_FOR_USER = 'handledException.loggedUser.forbiddenAgency',
  NOT_FOUND = 'handledException.notFound',
  CITY_DISTRICT_NOT_FOUND = 'handledException.cityDistrictNotFound',
  SKIP = 'handledException.skip',
}

export enum CollaboratersJob {
  JOB_COMMERCIAL = '/enums/user_jobs/JOB_COMMERCIAL',
  JOB_RESPONSABLE = '/enums/user_jobs/JOB_RESPONSABLE',
  JOB_MEMBER = '/enums/user_jobs/JOB_MEMBER',
  JOB_ATC = '/enums/user_jobs/JOB_ATC',
  JOB_SALESOFFICE = '/enums/user_jobs/JOB_SALESOFFICE',
  JOB_SALESSHOWROOM = '/enums/user_jobs/JOB_SALESSHOWROOM',
  JOB_MAILER = '/enums/user_jobs/JOB_MAILER',
  JOB_TRAVELING = '/enums/user_jobs/JOB_TRAVELING',
  JOB_AGENCYMANAGER = '/enums/user_jobs/JOB_AGENCYMANAGER',
  JOB_ADVISESHOWROOM = '/enums/user_jobs/JOB_ADVISESHOWROOM',
  JOB_SAVMANAGER = '/enums/user_jobs/JOB_SAVMANAGER',
  JOB_SELF = '/enums/user_jobs/JOB_SELF',
}

export enum CustomerRole {
  CRAFTER_ADMIN = 'CRAFTER_ADMIN',
  CRAFTER_MANAGER = 'CRAFTER_MANAGER',
  CRAFTER_EMPLOYEE = 'CRAFTER_EMPLOYEE',
}

export enum UserRole {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_APP_CRAFTER_COMPANY = 'ROLE_APP_CRAFTER_COMPANY',
  ROLE_APP_CRAFTER_STORE = 'ROLE_APP_CRAFTER_STORE',
}

export type ExtendedPublicationsWrite = Omit<
  Publication.Write,
  'targetSocieties'
> & {
  linkType: string
  agencies?: string[]
  societies?: string[]
  targetSocieties: string[]
  isNational: boolean
  isAgencies: boolean
  jobs?: string[]
  allCategories?: boolean
  planify?: boolean
}

export type ExtendedNotificationWrite = Merge<
  Notification.Write,
  {
    societyId: string | null
    societies?: Api.IriReference[]
    agencies?: Api.IriReference[]
    jobs?: string[]
    isAgencies?: boolean
    jobsIds?: string[]
    allCategories?: boolean
  }
>

export type ExtendedPublicationsRead = Omit<
  Publication.Read,
  'targetSocieties'
> & {
  targetAgencies: string[]
  targetSocieties: string[]
  linkType: string
  isNational: boolean
  societyId: string | null
  toolId: string
  jobs?: string[]
}

export type ExtendedTypesenseUser = Merge<
  User.Read,
  { society: Society.Read; agency: Agency.Read }
>

export type ExtendedPostMessage = Merge<
  PostMessageEventPostMessageRequest.Write,
  { newId: string }
>

export type ExtendedUserConversation = Merge<
  User.Read,
  {
    craftCompany?: CraftCompany.Read
    craftCompanyId?: string
    craftCompanyName?: string
    societyName?: string
    agencyName?: string
    agencyId?: string
    acRoles: {
      AC: UserRole | CustomerRole
    }
  }
>

export type ExtendedConversationList = {
  users: ExtendedConversation[]
  messages: ExtendedConversation[]
}

export type ExtendedConversation = Merge<
  GetSummaryConversationSummaryConversation.Read,
  {
    users: ExtendedUserConversation[]
  }
>

export type ExtendedConversationWrite = Merge<
  Conversation.Write,
  { newId: string; id?: string }
>

export type ExtendedAgency = Merge<Agency.Read, { society: Society.Read }>
export type Contact = Merge<
  User.Read,
  {
    ['@id']: `/users/${string}`
    agency?: Agency.Read
    society?: Society.Read
    avatarUrl?: string
    craftCompany?: CraftCompany.Read
    societyAgency?: Society.Read
  }
>

export type ExtendedOneConversation = Merge<
  Conversation.Read,
  {
    messages?: Message.Read[]
    users: ExtendedUserConversation[]
    blackList?: BlackList.SummaryConversationRead
  }
>

export type ExtendedUser = Merge<
  User.Read,
  {
    isNotAvailable: Boolean
    acRoles: {
      AC: UserRole | CustomerRole
    }
    registrationTokensBackOffice?: string[]
    userFunctionId?: string
    userfunction?: UserFunction.Read
  }
>
export type TemporaryTypesensenotification = Omit<
  Typesense.Publications,
  | 'url'
  | 'urlLabel'
  | 'documents'
  | 'documents.url'
  | 'documents.mimeType'
  | 'documents.pictureLink'
  | 'documents.playerEmbedUrl'
  | 'cover'
  | 'tool.id'
  | 'blocked_by'
>

export type ExtendedTypesensesocieties = Merge<
  Typesense.Societies,
  { 'society.id': string }
>

export type ExtendedTypesenseList = Merge<
  Typesense.Publications,
  {
    documents?: TypesenseDocuments[]
  }
>

type TypesenseDocuments = {
  mimeType?: string
  pictureLink?: string
  playerEmbedUrl?: string
  url?: string
}
