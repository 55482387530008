import { useQueryParamsState, drawerQueryParams } from '@aubade/core/libs'
import {
  FormDrawer,
  Form,
  useForm,
  Paragraph,
  ReadOnlyProvider,
} from '@aubade/core/ui-kit'
import {
  DrawerContent,
  useGetUser,
  isAdmin,
  isSociety,
} from '@aubade/domain/components'
import { toHydraId } from '@nartex/api-platform'
import { useMemo } from 'react'
import { useUrls } from 'src/App'
import { config } from 'src/config'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { usePublicationRights } from '../../adapters/rights'

import { FormContent } from './form/FormContent'
import type { FormType } from './types'
import { PublicationLinkEnum } from './types'

export function PublicationEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)

  const { page, id } = modalState

  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = Boolean(matchCreate || matchUpdate)

  return (
    <FormDrawer isOpenProps={Boolean(match)}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormEdit id={id} />}
      </DrawerContent>
    </FormDrawer>
  )
}

export function FormCreate() {
  const urls = useUrls()
  const { id: me, role, society } = useGetUser()
  const newId = useMemoOne(() => {
    return uuid()
  }, [])

  const { features } = config

  const defaultSocieties = useMemo(() => {
    if (!society || !isSociety(role)) return []
    return [toHydraId('societies', society.id)]
  }, [role, society])

  const { saveButtonProps, ...methods } = useForm<FormType>({
    mode: 'create',
    // @ts-ignore
    resource: 'publications',
    redirect() {
      return urls.aubade().publications()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
        linkType: PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK'],
        author: toHydraId('users', me!),
        isDraft: false,
        isNational: isAdmin(role),
        isAgencies: isSociety(role),
        publishedAt: new Date() as any,
        allCategories: true,
        isProAubade: false,
        societies: defaultSocieties,
        hasEaCard: features.eACard ? false : undefined,
      },
    },
  })

  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="publications.title.create"
      />
      <Form {...methods} bucket={{ type: 'Publication' }}>
        <FormContent newPulicationId={newId} {...saveButtonProps} />
      </Form>
    </>
  )
}

function FormEdit(props: { id: string }) {
  const { id } = props
  const urls = useUrls()

  const { saveButtonProps, ...methods } = useForm<FormType>({
    mode: 'update',
    resource: 'publications',
    id: id,
    hookFormOptions: {
      defaultValues: {
        publishedAt: new Date() as any,
      },
    },
    redirect() {
      return urls.aubade().publications()
    },
    blockNavigation: false,
  })
  const isDraft = methods.getValues('isDraft')
  const publishedAt = methods.getValues('publishedAt')
  const fullAuthor = methods.getValues('fullAuthor')

  const { useCanUpdate, useCanPartialEdit } = usePublicationRights()

  const canUpdate = useCanUpdate(fullAuthor)

  const canEdit =
    isDraft ||
    (canUpdate && new Date(publishedAt!).getTime() > new Date().getTime())

  const canPartialEdit = useCanPartialEdit(fullAuthor, publishedAt, isDraft)

  const title = isDraft ? 'publications.title.edit' : 'publications.title.show'

  return (
    <>
      <Paragraph fontWeight="bold" size="md" color="black.base" text={title} />
      <Form {...methods} bucket={{ type: 'Publication' }}>
        <ReadOnlyProvider value={!canEdit}>
          <FormContent
            canEdit={canEdit}
            canPartialEdit={canPartialEdit}
            publicationId={id}
            {...saveButtonProps}
          />
        </ReadOnlyProvider>
      </Form>
    </>
  )
}
