import type { SearchFilter } from '@aubade/core/adapters'
import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Surface,
  unit,
  Button,
  Paragraph,
  Chip,
  ButtonWithConfirm,
  Title,
  Table,
} from '@aubade/core/ui-kit'
import { useResourceList } from '@aubade/core/ui-kit/ResourceList/useResourceList'
import {
  IconDelete,
  IconPlus,
  IconSend,
  IconOutilsCouleurs,
  IconForbidden,
} from '@aubade/design/graphics'
import { ProfileCard, useGetUser } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type {
  ExtendedConversation,
  ExtendedConversationList,
  ExtendedUserConversation,
} from '@aubade/types'
import type { User } from '@aubade/types/api'
import { Box, HStack, VStack, Flex } from '@chakra-ui/react'
import { cleanHydraId } from '@nartex/api-platform'
import type { LogicalFilter } from '@nartex/data-provider'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useUrls } from '../../../../src/App'
import { ListHeader } from '../../../components'

import { useActions } from '../Actions'

import { ConversationListFilter } from './FilterForm'
import { useConversationFilters } from './useConversationFilters'

const templateColumns = '300px 1fr 1fr 1fr 140px'

export function ConversationList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { crudFilters, filtersStore } = useConversationFilters('summary')

  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      paddingX={unit('10')}
      paddingY={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
    >
      <ListHeader
        scope="summary"
        filters={
          <ConversationListFilter
            onChange={filtersStore.setState}
            filters={filtersStore.state as any}
          />
        }
        mainAction={
          <Button
            variant="primary"
            label={'actions.newConversation'}
            leftIcon={IconPlus}
            onClick={() => {
              searchParams.append('page', 'create')
              setSearchParams(searchParams)
            }}
          />
        }
      />

      <MultiResultList crudFilters={crudFilters} />
    </Surface>
  )
}

function useGetColumns() {
  const { id: me } = useGetUser()

  const translate = useTranslate()
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { users, lastMessage, name } = row.original

          const otherUsers = users.filter((user) => user.id !== me)
          if (otherUsers.length === 0)
            return (
              <VStack
                width="full"
                height={unit('60')}
                justifyContent={'center'}
                alignItems={'flex-start'}
                overflow={'hidden'}
              >
                <Paragraph
                  fontWeight="bold"
                  text="messages.label.lastMessage"
                />
                <Paragraph text={lastMessage?.content ?? ''} />
              </VStack>
            )
          if (otherUsers.length === 1) {
            const {
              avatar,
              firstname,
              lastname,
              isAvailable,
              craftCompany,
              userFunction,
              acRoles,
            } = otherUsers[0]
            const fullName = [firstname, lastname].filter(Boolean).join(' ')

            return (
              <Box width="full" height={unit('60')}>
                <ProfileCard
                  name={fullName}
                  isAvailable={isAvailable}
                  picture={avatar}
                  url=""
                  role={userFunction?.name ?? acRoles.AC ?? ''}
                  variant="big"
                  icon={craftCompany ? IconOutilsCouleurs : undefined}
                />
              </Box>
            )
          }

          const userNames = users
            .map((otherUser) =>
              [otherUser.firstname, otherUser.lastname]
                .filter(Boolean)
                .join(' '),
            )
            .join(', ')

          return (
            <Box width="full" height={unit('60')}>
              <ProfileCard
                multiple={users.length}
                name={name ?? translate('messages.list.group')}
                role={userNames}
                ellipsis
                variant="big"
              />
            </Box>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { users } = row.original

          const otherUsers = users.filter((user) => user.id !== me)
          if (otherUsers.length === 0)
            return (
              <VStack
                width="full"
                height={unit('60')}
                justifyContent={'center'}
                alignItems={'flex-start'}
                overflow={'hidden'}
              >
                <Paragraph text="Vous êtes le dernier correspondant de cette conversation" />
              </VStack>
            )

          if (otherUsers.length > 1) return <NoData />
          return <UserCraftCompany user={otherUsers[0]} />
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { users } = row.original

          const otherUsers = users.filter((user) => user.id !== me)
          if (otherUsers.length === 0) return <NoData />
          if (otherUsers.length > 1) return <NoData />

          return <UserAgencySociety user={otherUsers[0]} />
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { nbUnreadMessage } = row.original
          if (nbUnreadMessage === 0) return <Box height={unit('60')} />
          const unreadText =
            nbUnreadMessage === 1
              ? 'messages.fields.unread'
              : 'messages.fields.unreadPlural'
          return (
            <VStack
              width="full"
              height={unit('60')}
              justifyContent={'center'}
              alignItems={'flex-end'}
            >
              <Chip
                size="xsmall"
                variant="filled"
                label={translate(unreadText, {
                  count: nbUnreadMessage,
                })}
                tint="error"
                textProps={{ color: 'white.500' }}
              />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          return <Actions conversation={row.original} />
        },
      },
    ] satisfies ColumnDef<ExtendedConversation>[]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])
}

type ActionsProps = {
  conversation: ExtendedConversation
}

function Actions(props: ActionsProps) {
  const { conversation } = props
  const { id: me, agency } = useGetUser()

  const { id, users, blackList } = conversation
  const { deleteConversation, toggleBlackList, canBlock, isBlocked } =
    useActions(id, users, blackList)
  const [searchParams, setSearchParams] = useSearchParams()
  const translate = useTranslate()

  const otherUsers = useMemo(
    () => users.filter((user) => cleanHydraId(user.id) !== cleanHydraId(me)),
    [me, users],
  )

  const canBlockUser = Boolean(
    canBlock &&
      otherUsers.length === 1 &&
      otherUsers[0]?.agencyId &&
      cleanHydraId(otherUsers[0].agencyId) === cleanHydraId(agency.id),
  )

  return (
    <HStack
      gap="10px"
      width="full"
      height={unit('60')}
      justifyContent="flex-end"
      alignItems="center"
    >
      <ButtonWithConfirm
        dialogProps={{
          title: 'conversation.confirmTitle',
          children: translate('conversation.confirmText'),
        }}
        confirmButtonProps={{
          isDisabled: false,
          label: 'conversation.confirmButton',
          variant: 'primary',
        }}
        buttonProps={{
          isDisabled: false,
          variant: 'circle',
          leftIcon: IconDelete,
        }}
        onConfirm={() => deleteConversation()}
      />
      {canBlockUser && (
        <Button
          variant="circleList"
          leftIcon={IconForbidden}
          iconColor={isBlocked ? 'error.dark' : undefined}
          onClick={async () => {
            await toggleBlackList()
          }}
        />
      )}
      <Button
        variant="circleList"
        leftIcon={IconSend}
        onClick={() => {
          searchParams.append('id', id)
          setSearchParams(searchParams)
        }}
      />
    </HStack>
  )
}

function UserAgencySociety(props: { user: ExtendedUserConversation }) {
  const { user } = props
  const { agencyName, societyName } = user
  if (agencyName) {
    return (
      <VStack width="full" height={unit('60')} justifyContent={'center'}>
        <Paragraph
          text={[agencyName, societyName].filter(Boolean).join(' - ')}
          ellipsis
        />
      </VStack>
    )
  }
  if (!societyName) return <NoData />

  return (
    <VStack width="full" height={unit('60')} justifyContent={'center'}>
      <Paragraph text={societyName} ellipsis />
    </VStack>
  )
}

function UserCraftCompany(props: { user: ExtendedUserConversation }) {
  const { user } = props
  const { craftCompany } = user
  if (!craftCompany) return <NoData />

  return (
    <VStack width="full" height={unit('60')} justifyContent={'center'}>
      <Paragraph text={craftCompany.name} ellipsis />
    </VStack>
  )
}

export function NoData() {
  return (
    <HStack width="full" height={unit('60')}>
      <Paragraph size="sm" fontWeight="bold" text={'-'} />
    </HStack>
  )
}

type ListProps = {
  crudFilters: LogicalFilter<User.Read>[]
}

function MultiResultList(props: ListProps) {
  const { crudFilters } = props
  const columns = useGetColumns()
  const urls = useUrls()

  const [searchParams] = useSearchParams()

  const memoSearch = useMemo(() => {
    return searchParams.get(`summary.q`)
  }, [searchParams])

  const listFilters = useMemo(() => {
    const search = searchParams.get(`summary.q`)
    if (search) {
      const qFilter: SearchFilter = { q: search }
      if (crudFilters) {
        return [qFilter, ...crudFilters]
      }
      return [qFilter]
    }
    return crudFilters
  }, [searchParams, crudFilters])

  const [[data]] = useResourceList<any>({
    resource: 'summary/conversations',
    filters: listFilters,
    sort: [{ field: 'sort_value', order: 'desc' }],
    hasPagination: true,
    scope: 'summary',
    dataProviderName: 'default',
  })

  const { users, messages } = data as unknown as ExtendedConversationList

  const getRowProps = useCallback<GetRowProps<ExtendedConversation>>(
    (row) => {
      return {
        to: urls.aubade().conversationDetail(row.id),
      }
    },
    [urls],
  )

  return (
    <>
      <VStack gap={'30px'} alignItems="flex-start" width="full">
        <Box paddingX={unit('60')} width="full">
          {memoSearch && data && (
            <Title.H4
              color="darkGrey.500"
              fontWeight="bold"
              text="conversations.list.title.collaborators"
            />
          )}
        </Box>

        <Flex
          direction="column"
          gap={6}
          width={'full'}
          paddingLeft={unit('10')}
        >
          <Table
            templateColumns={templateColumns}
            columns={columns}
            noDataLabel="table.noData.conversation"
            getRowProps={useCallback<GetRowProps<ExtendedConversation>>(
              (row) => {
                return {
                  to: urls.aubade().conversationDetail(row.id),
                }
              },
              [urls],
            )}
            data={users}
          />
        </Flex>
      </VStack>
      {memoSearch && data && (
        <VStack gap={'30px'} alignItems="flex-start" width="full">
          <Box paddingX={unit('60')} width="full">
            <Title.H4
              color="darkGrey.500"
              fontWeight="bold"
              text="conversations.list.title.content"
            />
          </Box>

          <Flex
            direction="column"
            gap={6}
            width={'full'}
            paddingLeft={unit('10')}
          >
            <Table
              templateColumns={templateColumns}
              columns={columns}
              noDataLabel="table.noData.conversation"
              getRowProps={getRowProps}
              data={messages}
            />
          </Flex>
        </VStack>
      )}
    </>
  )
}
