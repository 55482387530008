import { makeForm } from '@aubade/core/ui-kit'

import type { FormType } from '../types'

const { Input, useWatch, useFormContext } = makeForm<
  FormType,
  'planify' | 'publishedAt'
>()

export function PlanifySection() {
  const planify = useWatch('planify')

  const { setValue } = useFormContext()

  return (
    <>
      <Input.Checkbox
        name="planify"
        label="publications.fields.planify"
        inputLabel="publications.title.planify"
        onChange={(value) => {
          if (!value) {
            setValue('publishedAt', new Date())
          }
        }}
      />

      {planify && (
        <Input.DateTime
          name="publishedAt"
          label="publications.fields.publishedAt"
          min
        />
      )}
    </>
  )
}
