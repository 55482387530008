import type { Merge } from '@aubade/core/libs'
import type { Publication, Document } from '@aubade/types/api'

import type { ExtendedUser } from '../../../../../packages/types/src/index'

export enum PublicationLinkEnum {
  'enum./publicationlink/WITHOUT_LINK' = 'enum./publicationlink/WITHOUT_LINK',
  'enum./publicationlink/TOOL_LINK' = 'enum./publicationlink/TOOL_LINK',
  'enum./publicationlink/EXTERNAL_LINK' = 'enum./publicationlink/EXTERNAL_LINK',
}

export type FormType = Merge<
  Publication.Write,
  {
    linkType: PublicationLinkEnum
    target: 'national' | 'society' | 'agency'
    agencies: string[]
    societies: string[]
    newId: string
    isNational: boolean
    isAgencies: boolean
    toolId: string
    documents: Document.Read[]
    author: `/users/${string}`
    fullAuthor: ExtendedUser
    withNotification?: boolean
    notificationTitle?: string
    notificationContent?: string
    planify?: boolean
    isInternal: boolean
    // Catégories
    allCategories?: boolean
    jobs?: string[]
    isProAubade?: boolean
    hasEaCard?: boolean
  }
>

export type CategoriesList = Categories[]

type Categories = {
  id: string
  name: string
  slug: string
}
