import type { QueryMetaData } from '@nartex/data-provider'
import { isTruthy } from '@nartex/stdlib'
import type { AxiosInstance } from 'axios'
import { chunk, isDeepEqual } from 'remeda'

import invariant from 'tiny-invariant'

import type { SearchResponse } from '../../src'

import {
  combineAsyncFunctions,
  createSignalAll,
} from './utils/combineAsyncFunctions'

import type { MultiSearchParams } from './index'

export type SearchError = { code: 404 | number; error: string }

type MultiSearchArguments = [
  searchParams: MultiSearchParams,
  options?: Pick<QueryMetaData, 'headers' | 'signal'>,
]

const MAX_SEARCHES = 50 // typesense's default limit
export function createMultiSearch(
  httpClient: Pick<AxiosInstance, 'request'>,
  baseURL: string,
) {
  return combineAsyncFunctions<
    MultiSearchArguments,
    SearchResponse<any> | SearchError
  >(async (args) => {
    checkHeaders(args.map(([, options]) => options?.headers))

    // divide the searches in chunks of max 50 searches per request (this is typesense's limit)
    const chunkedArgs = chunk(args, MAX_SEARCHES)
    const multiSearchesResults = await Promise.all(chunkedArgs.map(multiSearch))
    return multiSearchesResults.flat()
  })

  async function multiSearch(args: MultiSearchArguments[]) {
    const allOptions = args.map(([, options]) => options)
    const firstOptionHeaders = allOptions[0]?.headers // maybe check in dev check if headers are not the same

    const allSignals = allOptions.map((option) => option?.signal)
    const signal = createSignalAll(allSignals.filter(isTruthy))

    const allSearchParams = args.map(([searchParams]) => searchParams)

    const allCollections = allSearchParams.map((param) => param.collection)
    const debugString = `collections=${allCollections.join(',')}` // for easier filtering in the devtools

    const axiosResponse = await httpClient.request<
      | {
          results: (SearchResponse<any> | SearchError)[]
        }
      | { message: string }
    >({
      method: 'post',
      url: `${baseURL}/multi_search?${debugString}`,
      data: { searches: allSearchParams },
      headers: firstOptionHeaders,
      signal,
    })

    if ('message' in axiosResponse.data) {
      throw new Error(
        `Typesense error multiSearch failed: ${axiosResponse.data.message}`,
      )
    }

    return axiosResponse.data.results
  }
}

function checkHeaders(values: any[]) {
  if (process.env.NODE_ENV === 'development') {
    invariant(
      values.every((value) => isDeepEqual(value, values[0])),
      'Headers are not the same in all requests',
    )
  }
}
