import { debounceImmediateCallback } from '@nartex/stdlib'

type NonEmptyArray<T> = [T, ...T[]]

type AbortablePromise<T> = Promise<T> & { abort: () => void }

type ScheduledCall<Args, Result> = {
  args: Args
  resolve: (result: Result) => void
  reject: (error: unknown) => void
}
export function combineAsyncFunctions<Args extends any[], Result>(
  groupedCall: (args: NonEmptyArray<Args>) => Promise<Result[]>,
): (...args: Args) => AbortablePromise<Result> {
  let scheduledCalls: ScheduledCall<Args, Result>[] = []

  const scheduleCall = debounceImmediateCallback(async () => {
    if (!scheduledCalls.length) return

    const handledCalls = [...scheduledCalls] as NonEmptyArray<
      ScheduledCall<Args, Result>
    >
    scheduledCalls = []

    try {
      const results = await groupedCall(
        handledCalls.map((call) => call.args) as NonEmptyArray<Args>,
      )
      handledCalls.forEach((call, index) => call.resolve(results[index]))
    } catch (error) {
      handledCalls.forEach((call) => call.reject(error))
    }
  })

  return (...args: Args): AbortablePromise<Result> => {
    const { promise, reject, resolve } = PromiseWithResolvers<Result>()

    const scheduledCall = { args, resolve, reject }
    scheduledCalls.push(scheduledCall)
    scheduleCall()

    return Object.assign(promise, {
      abort: () => {
        scheduledCalls.splice(scheduledCalls.indexOf(scheduledCall), 1)
      },
    })
  }
}

export function createSignalAll(signals: AbortSignal[]): AbortSignal {
  if (signals.length === 1) return signals[0]

  const controller = new AbortController()

  signals.forEach((signal) =>
    signal.addEventListener('abort', () => {
      if (signals.every((s) => s.aborted)) {
        controller.abort()
      }
    }),
  )

  return controller.signal
}

// TODO use Promise.withResolvers() when available
function PromiseWithResolvers<T, Err = unknown>() {
  let onResolve: (value: T) => void = () => {}
  let onReject: (error: Err) => void = () => {}

  const promise = new Promise<T>((resolve, reject) => {
    onResolve = resolve
    onReject = reject
  })
  return { promise, resolve: onResolve, reject: onReject }
}
