import { Button, useModal } from '@aubade/core/ui-kit'
import { PictureCarousel } from '@aubade/core/ui-kit/Carousel'
import { IconRecherche } from '@aubade/design/graphics'
import { useGetUser } from '@aubade/domain/components'
import { AspectRatio, Box, VStack } from '@chakra-ui/react'

import { useUrls } from 'src/App'

import type { ExtendedTypesensePublication } from '../types'

import { FeedContent } from './FeedContent'
import { FeedPicture } from './FeedPicture'
import { PublicationPreview } from './PublicationPreview'

type Props = {
  publication: ExtendedTypesensePublication & {
    liked: boolean
    likeCount: number
  }
}

export function FeedCard(props: Props) {
  const { publication } = props
  const { documents } = publication
  const { id: me } = useGetUser()
  const urls = useUrls()
  const originalPublicationId = publication.id.split('*')[0]

  const isMyPublication = publication['author.id'] === me
  const [modal, openModal] = useModal({
    actions: isMyPublication && (
      <Button
        variant="circleList"
        rightIcon={IconRecherche}
        to={urls.aubade().feedEdit(originalPublicationId)}
      />
    ),
  })
  return (
    <>
      <Box borderRadius={'lg'} overflow="hidden">
        <VStack
          width="full"
          height="full"
          gap={0}
          m={0}
          p={0}
          alignItems="start"
          bg="lightGrey.500"
          cursor="pointer"
          onClick={async () => {
            await openModal(() => (
              <PublicationPreview publication={publication} />
            ))
          }}
        >
          <AspectRatio
            width="full"
            ratio={1}
            overflow="hidden"
            justifyContent={'center'}
          >
            <PictureCarousel multi={documents.length > 1}>
              {documents.map((fullDocument, index) => {
                return (
                  <FeedPicture
                    key={`${fullDocument.url} - ${index}`}
                    document={fullDocument}
                  />
                )
              })}
            </PictureCarousel>
          </AspectRatio>
          <AspectRatio width="full" ratio={3}>
            <FeedContent publication={publication} />
          </AspectRatio>
        </VStack>
      </Box>
      {modal}
    </>
  )
}
