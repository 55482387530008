import { useList } from '@aubade/core/adapters'
import { makeForm } from '@aubade/core/ui-kit'
import type { Tool } from '@aubade/types/api'

import { toHydraId } from '@nartex/api-platform'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import type { FormType } from '../types'
import { PublicationLinkEnum } from '../types'

const { Input, useWatch } = makeForm<FormType, 'linkType'>()

export function LinkSection() {
  const { setValue } = useFormContext()
  const linkType = useWatch('linkType')

  const [tools] = useList<Tool.Read>({
    resource: 'tools',
    dataProviderName: 'default',
  })

  const options = useMemo(() => {
    if (!tools) return undefined
    return tools.reduce((acc: any, objet) => {
      acc[toHydraId('tools', objet.id!)] = objet.name
      return acc
    }, {})
  }, [tools])

  return (
    <>
      <Input.Select
        label="publications.fields.link"
        name="linkType"
        optionsSelect={PublicationLinkEnum}
        placeholder="publications.placeholder.link"
        onChange={() => {
          setValue('url', null)
          setValue('urlLabel', null)
          setValue('toolId', null)
        }}
        defaultValue={PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK']}
      />
      {linkType ===
        PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK'] && (
        <>
          <Input.Text name="url" label="publications.fields.url" />
          <Input.Text
            name="urlLabel"
            label="publications.fields.urlLabel"
            maxLength={40}
          />
        </>
      )}
      {linkType === PublicationLinkEnum['enum./publicationlink/TOOL_LINK'] &&
        options && (
          <Input.Select
            name="toolId"
            label="publications.fields.tool"
            optionsSelect={options}
          />
        )}
    </>
  )
}
